import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Button, Navbar, Nav, NavItem, Container } from "react-bootstrap";
import LOGO from "../images/logo.png"

console.log(LOGO); // MV image

const Header = ({ siteTitle }) => (
  <Navbar bg="dark" variant="dark" expand="sm">
    <Container>
    <Navbar.Brand as={Link} href="/">
    <img
        alt=""
        src={LOGO}
        height="40"
        className="d-inline-block align-top"></img>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
      <NavItem href="/works">
          <Nav.Link as={Link} activeClassName="active" to="/works">
            Works
          </Nav.Link>
        </NavItem>
        <NavItem href="/company">
          <Nav.Link as={Link} activeClassName="active" to="/company">
            Company
          </Nav.Link>
        </NavItem>
        <NavItem href="/privacypolicy">
          <Nav.Link as={Link} activeClassName="active" to="/privacypolicy">
            Privacy Policy
          </Nav.Link>
        </NavItem>
        <NavItem href="/contact">
          <Nav.Link as={Link} activeClassName="active" to="/contact">
            Contact
          </Nav.Link>
        </NavItem>
      </Nav>
    </Navbar.Collapse>
    </Container>
  </Navbar>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
